import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Service, ServiceIcon, ServiceText } from '../../components/service/Service'
import { BarChart, Cloud, Dashboard } from '../../components/svg/Icon'
import { Section, SectionContant, SectionHead } from '../../layout/section/Section'

const ServieOne = props => {
	return (
		<Section className={props.className && props.className} id={props.id && props.id}>
			<Container>
				<Row>
					<Col xs='10' sm='7' md='5'>
						<SectionHead>
							<h2 className='title'>We provide various kind of service for you</h2>
						</SectionHead>
					</Col>
				</Row>
				<SectionContant>
					<Row className='g-gs'>
						<Col sm='6' lg='3'>
							<Service className='service-s1'>
								<ServiceIcon className='service-icon styled-icon styled-icon-s1'>
									<BarChart />
								</ServiceIcon>
								<ServiceText>
									<h4 className='title'>Appointment Booking</h4>
									<p>
										Chat, video, audio, and in-clinic consultation options with real-time
										availability of healthcare professionals
									</p>
								</ServiceText>
							</Service>
						</Col>
						<Col sm='6' lg='3'>
							<Service className='service-s1'>
								<ServiceIcon className='service-icon styled-icon styled-icon-s1'>
									<Dashboard />
								</ServiceIcon>
								<ServiceText>
									<h4 className='title'>Pharmacy Marketplace</h4>
									<p>
										A wide range of medications available for purchase with delivery tracking and
										notification including upload of prescriptions
									</p>
								</ServiceText>
							</Service>
						</Col>
						<Col sm='6' lg='3'>
							<Service className='service-s1'>
								<ServiceIcon className='service-icon styled-icon styled-icon-s1'>
									<Cloud />
								</ServiceIcon>
								<ServiceText>
									<h4 className='title'>Online Therapy</h4>
									<p>
										Secure and private platform for virtual therapy sessions with choice of
										therapists based on specialization and availability
									</p>
								</ServiceText>
							</Service>
						</Col>
						<Col sm='6' lg='3'>
							<Service className='service-s1'>
								<ServiceIcon className='service-icon styled-icon styled-icon-s1'>
									<Cloud />
								</ServiceIcon>
								<ServiceText>
									<h4 className='title'>Emergency Services</h4>
									<p>
										Access to emergency services and contacts and also quick access to medical
										history during emergencies{' '}
									</p>
								</ServiceText>
							</Service>
						</Col>
					</Row>
				</SectionContant>
			</Container>
		</Section>
	)
}

export default ServieOne
