import React from "react"
import { Section, SectionHead } from "../../layout/section/Section"
import { Container, Row, Col, Card, Form,  Input } from "reactstrap"
import { TextBlock, TitleH3 } from "../../components/textBlock/TextBlock"
import { Link } from "../../components/button/Button"

const CtaOne = (props) =>{
    return(
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="justify-content-center">
                    <Col xl="10">
                        <Row className="align-items-center g-0">
                            <Col md="7">
                                <Card className="card-shadow round-xl bg-indigo is-dark pb-4 pb-md-0">
                                    <div className="card-inner card-inner-xl">
                                        <TextBlock>
                                            <TitleH3>Contact Us</TitleH3>
                                        </TextBlock>
                                        <ul className="list list-nostyle fs-16px">
                                            <li>B13 Moddap Street</li>
                                            <li>Accra - Ghana</li>
                                            <li>info@myhealthgh.com</li>
                                            <li>+233 59 718 9836</li>
                                            {/* <li className="note text-warning">+ All future update releases for Free</li> */}
                                        </ul>
                                        {/* <ul className="btns-inline">
                                            <li><Link to="https://1.envato.market/reactdashlite" target="_blank" rel="noreferrer"  className="btn-xl btn-primary">Purchase Now for $28</Link></li>
                                        </ul> */}
                                    </div>
                                </Card>
                            </Col>
                            <Col md="5">
                                <Card className="card-shadow round-xl ms-lg-n7 ms-md-n5 mx-4 me-md-0 mt-md-0 mt-n4">
                                    <div className="card-inner card-inner-lg">
                                        <div className="form-block">
                                            <SectionHead className="section-head-sm">
                                                <h4 className="title">Do you have any other question?</h4>
                                            </SectionHead>
                                            <Form className="form-submit">
                                                <Row className="g-4">
                                                    <Col xs="12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="name">Your Name</label>
                                                            <div className="form-control-wrap">
                                                                <Input type="text" className="form-control form-control-lg" id="name" placeholder="Your Name"/>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs="12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="name">Enter Your Email</label>
                                                            <div className="form-control-wrap">
                                                                <Input type="text" className="form-control form-control-lg" id="email" placeholder="Enter Your Email"  />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs="12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="question">Your Question</label>
                                                            <div className="form-control-wrap">
                                                                <textarea className="form-control no-resize" id="question" placeholder="Your Question"></textarea>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs="12">
                                                        <Link to={`${process.env.PUBLIC_URL}/`} className="btn-lg btn-primary">Ask Question</Link>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>  
                </Row>
            </Container>
        </Section>
    )
}

export default CtaOne
