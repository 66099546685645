import React from 'react'

const Logo = props => {
	return (
		<a href={'/'} className={`logo-link h3 ${props.light ? '' : 'text-white'}`}>
			EdgeTellur
		</a>
	)
}

const BrandLogo = ({ src, alt, ...props }) => {
	return (
		<div className={['brand-item w-50 h-50', props.className ? props.className : null].join(' ')}>
			<img src={src} alt={alt} />
		</div>
	)
}

export { Logo, BrandLogo }
