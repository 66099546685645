import React, { useState } from 'react'
import { Collapse } from 'reactstrap'

const Accordion = ({ className, variation, ...props }) => {
	const [isOpen, setIsOpen] = useState('1')

	const toggleCollapse = param => {
		if (param === isOpen) {
			setIsOpen('0')
		} else {
			setIsOpen(param)
		}
	}

	return (
		<div
			className={[
				`accordion${variation ? ' accordion-s' + variation : ''}${
					className ? ' ' + className : ''
				}`,
			]}
		>
			<div className='accordion-item'>
				<div
					className={[`accordion-head${isOpen !== '1' ? ' collapsed' : ''}`]}
					onClick={() => toggleCollapse('1')}
				>
					<h6 className='title'>When are we available?</h6>
					<span className='accordion-icon'></span>
				</div>
				<Collapse className='accordion-body' isOpen={isOpen === '1' ? true : false}>
					<div className='accordion-inner'>
						<p>
							We provie 24hrs/7 days health care. This means we are always available to attend to
							your health needs
						</p>
					</div>
				</Collapse>
			</div>
			<div className='accordion-item'>
				<div
					className={[`accordion-head${isOpen !== '2' ? ' collapsed' : ''}`]}
					onClick={() => toggleCollapse('2')}
				>
					<h6 className='title'>What are some of the benefits of using our services?</h6>
					<span className='accordion-icon'></span>
				</div>
				<Collapse className='accordion-body' isOpen={isOpen === '2' ? true : false}>
					<div className='accordion-inner'>
						<p>
							We provide a wide range of health care services. This include online therapy, on line
							consultation(audio and video), and home service for emergency situations
						</p>
					</div>
				</Collapse>
			</div>
			<div className='accordion-item'>
				<div
					className={[`accordion-head${isOpen !== '3' ? ' collapsed' : ''}`]}
					onClick={() => toggleCollapse('3')}
				>
					<h6 className='title'>Is our service free?</h6>
					<span className='accordion-icon'></span>
				</div>
				<Collapse className='accordion-body' isOpen={isOpen === '3' ? true : false}>
					<div className='accordion-inner'>
						<p>Our service is not free but we provide a wide range of payment options.</p>
					</div>
				</Collapse>
			</div>
			<div className='accordion-item'>
				<div
					className={[`accordion-head${isOpen !== '4' ? ' collapsed' : ''}`]}
					onClick={() => toggleCollapse('4')}
				>
					<h6 className='title'>How do I get started?</h6>
					<span className='accordion-icon'></span>
				</div>
				<Collapse className='accordion-body' isOpen={isOpen === '4' ? true : false}>
					<div className='accordion-inner'>
						<p>
							Our product is still in development, you can join our waitlist to be notified when we
							launch
						</p>
					</div>
				</Collapse>
			</div>
			<div className='accordion-item'>
				<div
					className={[`accordion-head${isOpen !== '5' ? ' collapsed' : ''}`]}
					onClick={() => toggleCollapse('5')}
				>
					<h6 className='title'>Can I cancel my subscription?</h6>
					<span className='accordion-icon'></span>
				</div>
				<Collapse className='accordion-body' isOpen={isOpen === '5' ? true : false}>
					<div className='accordion-inner'>
						<p>Absolutely, you can cancel your subscription anytime you want to.</p>
					</div>
				</Collapse>
			</div>
		</div>
	)
}

export default Accordion
