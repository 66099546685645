const FeatureOneList = [
	'Real-time availability of healthcare professionals',
	'Pharmacy delivery tracking and notifications',
	'Secure storage of user health records',
]

const FeatureTwoIcon = [
    {icon: "star-fill"},
    {icon: "star-fill"},
    {icon: "star-fill"},
    {icon: "star-fill"},
    {icon: "star-fill"},
]

const FeatureThereeList = [
    "Where meetings come together in one.",
    "Workspaces forevery meeting.",
    "Amazing Feature.For Enhanced performance.",
]

export { FeatureOneList, FeatureTwoIcon, FeatureThereeList }
